.name-title {
    text-align: center;
    color: black;
}

.name-title h1 {
    font-size: 2em;
    margin-bottom: 0;
}

.name-title h2 {
    font-size: 1.5em;
    margin-top: 0;
}