.Content .Logo {
  width: auto; 
  padding-top: .5rem;
  height: 6vh;
  -webkit-max-content: none;
}

.Content .Logo {
  top: .5rem; /* Adjust as needed */
  left: 1rem; /* Adjust as needed */
  display: flex;
  align-items: center;
}
@media (max-width: 1000px) {
  .Content .Logo {
    height: 5vh;
    padding: 1rem;
  }
}