.Consultation {
    max-width: 70rem;
    width: 70%;
    margin: 0 auto;
}
.Consultation > * {
    margin-bottom: 2rem;
}
.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centerText {
    text-align: center;
}

.google-cal { 
    border: 0;
    width: 100%;
    height: 41rem; 
}

.StepIndicator{
    width: 70%;
}

.StepIndicator .step-content{
    width: 100%;
    height: fit-content;
}

.Consultation .StepIndicator .step-content .SuccessPage {
    height: fit-content;
    margin-top: 2rem;
}

@media (max-width: 1000px) {
    .Consultation {
        width: 95%;
    }
    .Consultation .ConsultationForm {
        width: 90%;
    }
    .Consultation .ConsultationForm {
        margin-top: .5rem;
    }
}