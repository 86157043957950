.Collaboration {
    max-width: 70rem;
    width: 70%;
    margin: 0 auto;
}
.Collaboration > * {
    margin-bottom: 2rem;
}
.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centerText {
    text-align: center;
}

.Collaboration .center .GradientBorderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Collaboration .TextBlock {
    width: 40rem;
}

.specialNote p {
    font-size: 16px;
    color: #90A8BC;
}

.Collaboration .Border .GradientBorderWrapper {
    border: 1px solid #90A8BC;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.Collaboration .Border .GradientBorderWrapper, .Collaboration .Border .TextBlock {
    width: 27rem;
}
.Collaboration .Border .TextBlock {
    width: 75%;
}
@media (max-width: 1000px) {
    .Collaboration .TextBlock {
        width: 100%;
    }
    .Collaboration{
        width: 95%;
    }
}