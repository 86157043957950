.MeshImgText img{
    height: 15rem;
    background-color: black;
}
.MeshImgText.Left img{
    transform: translateX(15%);
}
.MeshImgText.Right img{
    transform: translateX(-15%);
}
.MeshImgText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 55%;
}
.MeshImgText.Right {
    flex-direction: row-reverse;
}
.MeshImgText.Left .TextBlock {
    margin-left: 5rem;
    margin-right: 2rem;
}
.MeshImgText.Right .TextBlock {
    margin-left: 2rem;
    margin-right: 5rem;
}
.MeshImgText .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 22rem;
    border: 1px solid #202529;
}

.MeshImgText .TextBlock {
    min-width: 15rem;
}

.MeshImgText .image-container img {
    border: solid 20px #202529;
}

@media (max-width: 960px) {
    .MeshImgText.Left img{
        transform: none !important;
    }
    .MeshImgText.Right img{
        transform: none !important;
    }

    .MeshImgText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        padding: 0;
        width: 70%;
        gap: 2rem;
    }
    .MeshImgText.Right, .MeshImgText.Left {
        flex-direction: column;
    }
    .MeshImgText.Left .TextBlock {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .MeshImgText.Right .TextBlock {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .MeshImgText .TextBlock {
        max-height: none !important;
        min-width: 0 !important;
    }
    .MeshImgText .text-container .TextBlock {
        padding: 2rem;
    }
    .MeshImgText, .MeshImgText.Left,.MeshImgText.Right {
        padding: 4rem;
    }
}