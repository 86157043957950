.section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.section.image-right {
    flex-direction: row-reverse;
}

.section img {
    width: 40rem;
    height: fit-content;
}

.section.top,
.section.bottom,
.section.left,
.section.right {
    flex-direction: column;
}

.section.bottom {
    flex-direction: column-reverse;
}

.section.left {
    flex-direction: row;
}

.section.right {
    flex-direction: row-reverse;
}

.section .text-block {
    flex-grow: 1;
}

.section.top .image-container,
.section.bottom .image-container {
    display: flex;
    gap: 1em; /* adjust as needed */
}
.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1300px) {
    .section, .section.top, .section.bottom, .section.left, .section.right, .section.image-right {
        flex-direction: column;
    }
    .section img {
        width: 70%;
    }
}