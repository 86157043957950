.body-section {
  min-height: 62vh;
}
* {
  font-family: 'Proxima Nova', sans-serif;
  font-size: 18px;
  color: #2D3F4A;
}
h1, h1 a {
  font-size: 50px;
  color: #202529;
}
h2, h2 a {
  font-size: 38px;
  color: #202529;
}
h3, h3 a {
  font-size: 26px;
  color: #202529;
}

h4, h4 a {
  font-size: 22px;
  color: #202529;
}
strong {
  font-size: inherit;
  font-weight: 800;
}
.App {
  display: flex;
  flex-direction: column; /* Arranges the children of .App in a column */
  justify-content: space-between; /* Distributes the children of .App evenly along the vertical axis */
  height: 100vh; /* Makes .App take up the full height of the viewport */
  width: 100vw; /* Makes .App take up the full width of the viewport */
  background: white;
  background-repeat: no-repeat; 
  background-size: cover;
}

.body {
  flex-grow: 1; /* Makes .body take up the remaining space in .App */
  overflow-y: auto; /* Adds a scrollbar to .body if its content overflows */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar {
  display: flex; /* Enables Flexbox */
  flex-direction: row; /* Arranges the children of the navbar in a row */
  width: 100%; /* Makes the navbar span the full width of the page */
  justify-content: space-between;
}

.navbar .headerContent {
  display: flex;
  align-items: center;
}

.navbar .navLinks {
  display: flex;
  align-items: center;
}

.App .body {
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.footer {
  width: 100%;
}

a {
  color: #1E90FF; /* Dodger Blue */
  text-decoration: none; /* Optional: Removes underline */
}
a:hover, a:focus {
  color: #00BFFF; /* Deep Sky Blue */
  text-decoration: underline; /* Re-adds underline on hover/focus for clarity */
}

@media ( max-width: 1000px ) {
  * {
    font-size: 17px;
  }
  h1, h1 a {
    font-size: 34px;
  }
  h2, h2 a {
    font-size: 24px;
  }
  h3, h3 a {
    font-size: 22px;
  }
  
  h4, h4 a {
    font-size: 20px;
  }
  strong {
    font-size: inherit;
    font-weight: 800;
  }
}