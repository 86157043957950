@import url('https://fonts.googleapis.com/css2?family=Birthstone+Bounce&display=swap');

.BusinessPolicies {
    max-width: 70rem;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}
.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centerText {
    text-align: center;
}
  
.BusinessPolicies del {
    font-size: 40px;
    font-family: 'Birthstone Bounce', cursive;
    text-decoration-line: none;
}

@media (max-width: 1000px) {
    .BusinessPolicies{
        width: 90%;
    }
}