.Work {
    max-width: 70rem;
    width: 70%;
    margin: 0 auto;
}
.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
.Work .Section1, .Work .Section2{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}
.Work .Section1 img{
    height: 19rem;
}
.Work .Section2 img {
    height: 12.5rem;
}
.Work .Section2{
    gap: 4rem;
    z-index: 10;
}
.Work .Section1{
    z-index: 10;
}

.ReactModal__Overlay {
    background-color: rgb(68, 68, 68);
  }
  
  .ReactModal__Content {
    color: lightsteelblue;
  }
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 10;
}
.Work img {
  cursor: pointer;
}

.ReactModal__Body--open .modalimg {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1000px) {
  .Work .Section1{
    gap: .5rem;
  }
  .Work .Section2{
    gap: .5rem;
  }
  .Work .Section1 img{
    height: 16rem;
  }
  .Work .Section2 img{
    height: 11.5rem;
  }
  .ReactModal__Body--open .modalimg img {
    max-width: 90vw !important;
  }
}