.StepIndicator{
  width: 100%;
}
.StepIndicator.step-content{
  height: 1rem;
}
.indicator-line {
  display: flex;
  justify-content: space-between; /* distribute lines evenly */
  gap: .25rem;
  width: 70%; /* take up 70% of the available width */
}

.step-container {
  text-align: center;
  flex: 1;
}

.step-indicator {
  position: relative;
  display: block;
  height: 2px; /* adjust this value to control the thickness of each line */
  background-color: #ccc; /* default color for non-active steps */
  margin-bottom: 8px;
}

.step-title {
  display: block;
  font-size: 11px;
}

.step-indicator::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* span the full width of the indicator */
  height: 2px; /* match the thickness of each line */
  background-color: #ccc; /* default color for non-active steps */
}

.step-indicator.active {
  background-color: #90A8BC; /* active step color */
}

.step-indicator.active::before {
  background-color: #90A8BC; /* active step color */
}

.custom-button {
  width: 397px;
  height: 62px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #4c4e4e solid 1px;
}

.submit-enabled {
  background-color: #90A8BC;
  color: white;
}

.submit-disabled {
  color: #90A8BC; 
}

.submit-enabled:hover {
  opacity: 90%;
  cursor: pointer;
}

.submit-disabled:hover {
  cursor: not-allowed; /* or any other cursor shape that makes sense */
}
