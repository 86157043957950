@import url('https://fonts.googleapis.com/css2?family=Birthstone+Bounce&display=swap');

.About {
    max-width: 70rem;
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.About .image-container img {
    border-radius: 50%;
    height: auto;
    width: 20vw;
}

.About > * {
    margin-bottom: 3rem;
    width: 100%;
}

.Header {
    width: 70%;
}

.About strong {
    font-size: 40px;
    font-family: 'Birthstone Bounce', cursive;
    color: #90A8BC;
}


@media (max-width: 1000px) {
    .About .image-container img {
        width: 35vw;
        height: auto;
    }
    .About {
        width: 90%;
        padding-bottom: 5rem;
    }
}