.SuccessPage{
    height: 62vh;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #90A8BC;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #90A8BC;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  
  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #90A8BC;
    }
  }

  .success-page {
    width: 30rem;
    background-color: #F8F7F7;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.checkmark {
  margin-bottom: 10px;
}

.success-message {
  color: #5E483F;
  font-size: 24px;
  padding-left: .5rem;
  padding-right: .5rem;
}
.ok-button {
  background-color: #90A8BC;
  color: white;
  padding: .3rem 1rem;
  border: none;
  cursor: pointer;
  border-radius: .3rem;
  margin-bottom: 60px;
}

/* You can also add some hover effect if needed */
.ok-button:hover {
  opacity: 0.8;
}
