/* src/components/Footer.css */
#Footer {
  background-color: #f7f7f7; /* Default background color for the footer */
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  margin-top: 2.5rem;
}

#Footer .TextBlock {
  display: block; /* Make each TextBlock component a block element */
  margin-bottom: 10px; /* Add some space between each TextBlock component */
}

/* You can also add some styles to the TextBlock component itself if needed */

#Footer a {
  color: #BBC9D9; /* Default link color for the footer links */
}

.Footer .footer-links{
  display: flex;
  gap: 2.5rem;
}

@media (max-width: 1100px) {
  .Footer .footer-links {
    flex-direction: column;
    gap: 1rem;
  }
}