.navbar {
  display: flex; /* Enables Flexbox */
  flex-direction: row; /* Arranges the children of the navbar in a row */
  width: 100%; /* Makes the navbar span the full width of the page */
  justify-content: space-between;
}

.navbar a {
  white-space: nowrap;
}

.navbar .headerContent {
  padding-left: 5rem;
  display: flex;
  align-items: center;
}

.navbar .navLinks {
  display: flex;
  align-items: center;
}
  
  /* The .navbar ul rule styles the list in the navbar */
.navbar ul {
    list-style-type: none; /* Removes the default list bullet points */
    display: flex; /* Makes the list items display in a row instead of a column */
    justify-content: flex-end; /* Aligns the list items to the right of the navbar */
    padding-right: 2rem; /* Adds some space to the right of the list */
    padding-top: .5rem; /* Adds some space to the top of the list */
}
  
  /* The .navbar ul li rule styles the list items in the navbar */
  .navbar ul li {
    padding: 0 2rem; /* Adds some space to the left and right of each list item */
  }
  
  /* The .navbar a rule styles the links in the navbar */
  .navbar a {
    text-decoration: none; /* Removes the default link underline */
    color: #BBC9D9; /* Makes the link text white */
  }
  
  /* The .navbar .active-link rule styles the active link in the navbar */
  .navbar .active-link {
    color: #90A8BC; /* Changes the color of the active link to grey */
  }

  /* The .navbar-toggle rule styles the hamburger icon */
  .navbar-toggle {
    display: none; /* Initially hidden */
    cursor: pointer;
    font-size: 2rem;
    color: black;
  }

  @media (max-width: 1450px) {
    .header {
      z-index: 10;
    }

    .navbar {
      display: flex;
      justify-content: space-between; /* This makes the contents of navbar take up the full width, with headerContent centered in between navbar-toggle and the navigation list */

      .headerContent {
          width: 100%;
          display: grid;
          place-items: center;
          height: auto; /* You might want to add some height here if you're not using flexbox for layout */
      }
    }

    .navbar .navbar-toggle {
      display: block; /* Shows the hamburger icon */
      grid-column: 2; /* Places the hamburger icon in the second column */
      grid-row: 1; /* Places the hamburger icon in the first row */
      align-self: center; /* Centers the hamburger icon vertically */
      margin-right: 2rem; /* Adds 2rem space to the right of the hamburger icon */
      z-index: 2;
    }
    .navbar-toggle.show{
      fill: #BBC9D9 !important;
      stroke: #BBC9D9 !important;
    }
    .navbar ul {
      display: none; /* Initially hides the list items */
      grid-row: 2; /* Places the list items in the second row */
    }

    .navbar ul li {
      padding: 2rem 0; /* Adds vertical padding to each list item */
    }
    
    .navbar ul.collapsed {
      position: fixed; /* Fixes the position of the ul relative to the viewport */
      top: 0; /* Positions the ul at the top of the viewport */
      left: 0; /* Positions the ul at the left of the viewport */
      width: 100%; /* Makes the ul span the full width of the viewport */
      height: 100%; /* Makes the ul span the full height of the viewport */
      background-color: black;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      animation: slideDown 0.5s ease-out;
      margin: 0;
      padding: 0;
    }

    .navbar a {
      color: #BBC9D9; /* Makes the link text white */
    }

  }