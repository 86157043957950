.Welcome {
  max-width: 70rem;
  width: 70%;
  margin: 0 auto;
}
.Welcome > * {
  margin-bottom: 2rem;
}
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.centerText {
  text-align: center;
}

.Section1 {
  display: flex;
  justify-content: space-between;
  align-items: center; 
}
.images1{
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
.images1 img {
  height: 19rem;
  border: solid 20px #202529
}
.Section1 .SectionText{
  width: 70%;
}
.Section2, .Section4 {
  width: 70%;
}
.Welcome .Section3 {
  display: flex;
  justify-content: space-between;
}
.Welcome .Section3 img {
  width: 45%;
}

.Welcome .Section5 .MeshImgText.Right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Welcome .Section5 .MeshImgText.Left {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1000px) {
  .Welcome {
    width: 90%;
    max-width: 90%;
  }
  .Welcome .Section1.center {
    width: 100%;
    padding: 0;
  }
  .Welcome .Section1 .SectionText,
  .Welcome .Section2,
  .Welcome .Section4 {
    width: 95%;
  }
  .Welcome .images1 {
    flex-direction: column;
  }
}