.Packaging {
    border: 1px solid #90A8BC;
    box-shadow: 5px 5px 10px rgba(219, 219, 219, 0.5);
    padding: 10px;
    border-radius: 20px;
    padding-left: 2rem;
    color: #5E483F;
}

.Includes ul {
    list-style-type: disc;
    padding-left: 20px;
}
.Price{
    font-size: 38px;
    font-weight: bold;
}
.PriceNote {
    color: #90A8BC;
}