.Investment {
    max-width: 70rem;
    width: 70%;
    margin: 0 auto;
}
.Investment > * {
    margin-bottom: 2rem;
}
.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.centerText {
    text-align: center;
}

.Investment .TextSize p {
    font-size: 30px;
}

.Investment .center .GradientBorderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.CCrow1 {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}
.Packaging {
    width: 29rem;
}

.Headshots .Packaging, 
.ContentCreation .Packaging, 
.OfferingsLaunches .Packaging, 
.EventPhotography .Packaging {
    width: 20rem;
}

.DiscountsSection h3, .DiscountsSection p {
    text-align: left;
}

.DiscountsSection ul {
    text-align: left;
    padding-left: 20px;
}

.Customized h5 {
    color: #90A8BC;
    font-weight: normal;
}

.BrandPhotography, .OfferingsLaunches, .Customized {
    background: #F8F7F7;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
}
.Packaging {
    background: white;
}
.Section1 {
    padding-left: 2rem;
    padding-right: 2rem;
}

@media ( max-width: 1000px ) {
    .Investment {
        width: 90%;
    }
    .Investment .Section2 {
        width: 100%;
    }
    .Investment .Packaging {
        width: 90%;
    }
    .Investment .CCrow1 {
        flex-direction: column;
        gap: 1.5rem;
        width: 100%;

    }
}