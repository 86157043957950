.ContactMe{
    max-width: 40rem;
    width: 70%;
    min-height: 62vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}
.row {
    display: flex;
    justify-content: space-between;
  }

.row input,label,select{
    width: 100%;
}

.contactType ul{
    list-style: none;
}
.contactType.section{
    display: block;
}
.Message{
    display: flex;
    flex-direction: column;
}
.Message textarea{
    width: 100%;
}
.Message.input-container {
    width: 100%;
}

.row .input-container {
  padding-left: 20px; /* Adjust this value as needed */
}
.EmailSection label {
    width: 40%;
}

.ContactMe .section2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}