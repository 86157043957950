.ConsultationForm {
    max-width: 40rem;
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.row {
  display: flex;
  justify-content: space-between;
}

.row input,label,select{
    width: 100%;
}

.packages ul{
    list-style: none;
}
.packages.section{
    display: block;
}
.row div {
  margin-right: 20px;
}

label {
  display: block;
}

.ConsultationForm .section {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
}

.custom-button {
  width: 397px;
  height: 62px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-enabled {
  background-color: #90A8BC;
  color: white;
}

.submit-disabled {
  color: #90A8BC; 
}

.contactType li{
  height: 2rem;
}
.ContactMe input,textarea{
  border: 1px solid rgba(0, 0, 0, 0.4);
}