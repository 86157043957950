.ClientExperience {
    max-width: 70rem;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}
.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centerText {
    text-align: center;
}

@media (max-width: 1000px) {
    .ClientExperience{
        width: 90%;
    }
}