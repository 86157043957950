.PrivacyPolicy {
    max-width: 70rem;
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}
.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.PrivacyPolicy h2 {
    text-align: center;
}

@media (max-width: 1000px) {
    .PrivacyPolicy{
        width: 90%;
    }
}